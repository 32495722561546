import React, { useState,useEffect } from 'react'
import {useTable} from "react-table";
import TableLoader from './TableLoader'
//import 'react-table/react-table.css';

const ReactGenericTable = ({ data, tableclassName,hideColumns, filterable, columns, loading = false }) => {

	const [tableData,setTableData] = useState(data);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	  } = useTable({
		columns,
		data,
		tableclassName,
		initialState:{
			hiddenColumns: hideColumns
		}
	})

	useEffect(() => {
		setTableData(data);
	}, [data]);



    return (
		<table {...getTableProps()} className={tableclassName}>
			<thead>
				{headerGroups.map(headerGroup => (
				<tr {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map(column => (
					<th {...column.getHeaderProps()}>{column.render('Header')}</th>
					))}
				</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
				prepareRow(row)
				return (
					<tr {...row.getRowProps()}>
					{row.cells.map(cell => {
						return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
					})}
					</tr>
				)
				})}
			</tbody>
		</table>

		// <ReactTable
		// 	loading={loading}
		// 	data={tableData}
		// 	filterable={filterable}
		// 	columns={columns}
		// 	showPagination={false}
		// 	minRows={5}
		// 	loadingText={<TableLoader />}
		// />
    );
}

export default ReactGenericTable;