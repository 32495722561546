import React , { useState }  from 'react';
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'
import { UsersContextProvider } from './contexts'
import { LoadersContextProvider } from './contexts'
import TopLoader from './components/TopLoader'
import Routes from './Routes';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';


function App() {

  const [loading] = useState(false)

  const renderLoading = () => 
    <div>Loading...</div>
  
  const renderRoutes = () => Routes()

  return (
    <BrowserRouter>
      <LoadersContextProvider>
        <UsersContextProvider>
          <TopLoader color={"#81c030"}/>
          <ToastContainer transition={Slide} />   
          {loading ? renderLoading() : renderRoutes()}
        </UsersContextProvider>        
      </LoadersContextProvider>
    </BrowserRouter>
  );
}

export default App;
