import * as Yup from 'yup'

const ClientSchema = Yup.object().shape({
    ClientId: Yup.string()
        .required('ClientId field is required'), 
    ClientUserName: Yup.string()
        .required('contactName field is required'),  
    phone: Yup.number()
        .required('phone field is required'),
    BirthMonth: Yup.number()
        .required('BirthMonth field is required'),
    BirthYear: Yup.number()
        .required('BirthYear field is required'),
    BirthYear: Yup.number()
        .required('BirthYear field is required'),
    gender: Yup.string()
        .required('gender field is required'),
    // userTags: Yup.string()
    //     .required('userTags field is required'),    
    GroupName: Yup.string()
        .required('GroupId field is required'),
    // ethinicity: Yup.number()
    //     .required('ethinicity field is required'),
    address: Yup.string()
        .required('address field is required'),
    city: Yup.string()
        .required('city field is required'),
    // provinceState: Yup.number()
    //     .required('provinceState field is required'),
    postal: Yup.string()
        .required('postal field is required'),
    email: Yup.string()
        .required('email field is required'),
    numDependants: Yup.number()
        .required('numDependants field is required'),
    consultNotes: Yup.string()
        .required('consultNotes field is required'),
    TypeofSurvey:Yup.number()
        .required('adultSurveyType field is required'),
    Language: Yup.string()
        .required('Language field is required'),
    ShowSurveyFeedback: Yup.string()
        .required('ShowSurveyFeedback field is required'),
})

export default ClientSchema