import React,{useState,useEffect} from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ClientForm } from '../forms' 

const ClientModal = ({ isShowing, hide, clientId,setClientId}) => {
  const onClosed = () => {
    setClientId(null)
    
  }
  const [client, setClient] = useState(null)
  
  useEffect(() => {
    if(clientId !== null) {
        // http.get('api/client/GetClientByClientID?ClientID=404838')
        // .then(function (response) {
        //     console.log("useEffect",response.data)
        //     //setLoggedInUser(response.data)
              // setClient([response.data])
        //   })
        //   .catch(function (error) {
            
        //   })
    }
}, []);
    return (
      <div>
        <Modal isOpen={isShowing} toggle={hide} onClosed={onClosed}>
          {clientId !== null ? (
            <ModalHeader toggle={hide}>Edit Client</ModalHeader>
          ) : (
            <ModalHeader toggle={hide}>Add Client</ModalHeader>
          )}
  
          <ModalBody>
            <div className="col-12">
              <ClientForm client={client} clientId={clientId} hide={hide} />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };
  
  export default ClientModal;