import axios from 'axios';
import moment from 'moment';

const http = axios.create({
  baseURL:
     process.env.NODE_ENV === 'production'
       ? process.env.REACT_APP_API_BASE_URL
       : process.env.REACT_APP_API_BASE_URL,
     withCredentials: true,
});

// Add a request interceptor
http.interceptors.request.use(
  function (config) {
    let AccessTokenInfo =localStorage.getItem("Auth_Token_Info");
    AccessTokenInfo = JSON.parse(AccessTokenInfo)
    console.log(AccessTokenInfo)
    if(AccessTokenInfo != null && AccessTokenInfo != undefined && AccessTokenInfo.access_token !=null){
      //subtract 10 min not over expire time before that
     let expires = moment(AccessTokenInfo.expires_in).subtract(10, 'minutes');
    //  if(moment().isBefore(expires)){
      let token = AccessTokenInfo.access_token;
      config.headers.Authorization = `Bearer ${token}`;
      
    //  }
    //  else{
    //    console.log("else")
    //     //clear local storage and notify above action redirect to login page 
    //  }
    }
    else{
      //clear local storage and notify above action redirect to login page 
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default http;
