import React, { useState, useContext } from 'react'
import {
  Container, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav,
  NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import Avatar from 'react-avatar'
import { Link, withRouter } from 'react-router-dom'
import { UsersContext } from '../contexts'

import logo from '../assets/images/logo.png';

const Header = ({ history, location }) => {

    const [isOpen, setisOpen] = useState(false);

    const { user, setLoggedInUser } = useContext(UsersContext)

    const toggle = () => {
        setisOpen(!isOpen)
    }
    const logout=()=>{
        localStorage.clear();
        setLoggedInUser(null);
        history.push('/login');
    }
    return (

        <header>
            <Navbar light expand="md" fluid className={"header-bar"} >
                <Container>
                    <NavbarBrand tag={Link} to="/">
                        <img className="brand-img"
                            src={logo}
                            alt="My Outcomes"
                        />
                    </NavbarBrand>

                    <NavbarToggler onClick={toggle} />

                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto align-items-center" navbar>
                                {
                                    user !== null &&
                                <NavItem className="mx-4" >
                                    <Link to='/clients' className=""> Clients </Link>
                                </NavItem>
                                }
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        <i className="fas fa-bell font-dark-red" />
                                    </DropdownToggle>
                                    <DropdownMenu right>

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {
                                     user ===null &&
                                <NavItem className="mx-4" >
                                    <Link to='/login' className=""> Login</Link>
                                </NavItem>
                                }
                                {
                                    user !== null &&
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        
                                        <Avatar name={user.userName} size="30" className="float-left" round={true} />
                                <p className="float-left"> {user.userName}</p>
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        {/* <DropdownItem onClick={() => history.push('/settings/profile')}>
                                            Profile
                                        </DropdownItem> */}
                                        <DropdownItem onClick={logout}>
                                            Logout
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
}
                        </Nav>

                    </Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default withRouter(Header)