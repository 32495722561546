import * as Yup from 'yup'

const GroupSchema = Yup.object().shape({
    groupId: Yup.number()
        .required('groupId field is required'), 
    groupName: Yup.string()
        .required('groupName field is required'),  
    isGSRSGroup: Yup.boolean()
        .required('isGSRSGroup field is required'),
    languageId: Yup.string()
        .required('languageId field is required'),
    ProviderUsername: Yup.string()
        .required('providerId field is required'),
})

export default GroupSchema