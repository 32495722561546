import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const Context = createContext({});

export const Provider = ({ children }) => {
  
  const [user, setUser] = useState(null);

  const setLoggedInUser = user => {
    setUser(user)
  }

  // Make the context object:
  const usersContext = {
    user,
    setLoggedInUser,
  }

  return (<Context.Provider value={usersContext}>{children}</Context.Provider>);
};

export const { Consumer } = Context;

Provider.propTypes = {
  user: PropTypes.object,
};

Provider.defaultProps = {
  user: null,
};
