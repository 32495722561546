import React,{ useContext, useState, useEffect} from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import  console from 'console';
import http from '../utils/http';
import notify from '../utils/notify'
import moment from 'moment';
import {FormGroup, Label} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button } from '../components'
import { UsersContext } from '../contexts'
import { GroupSchema }  from '../validations'
import { LoadersContext } from '../contexts'

//import { group } from 'console';
const GroupForm = ({groupId, hide, group}) => {

    const { user, setLoggedInUser } = useContext(UsersContext);
console.log("user",user)
    const { showSpinner } = useContext(LoadersContext)

    let history = useHistory();

    const [languages, setLanguages] = useState([]);

    const yesNo = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        },
    ];

    useEffect(() => {
     
        if(languages.length===0){
            http.get('api/client/GetLanguages')
            .then(function (response) {
                console.log("GetLanguages",response.data)
                setLanguages(response.data)
            })
            .catch(function (error) {
            
            })
        }
    },[languages]);


    return (

        <>
            {

                (groupId === null || (groupId !== null && group !== null)) &&

                <Formik
                    initialValues={{ 
                                groupId: group!==null ? group.groupId :'',
                                groupName : group!==null ? group.groupName :'',
                                isGSRSGroup : group!==null ? group.isGSRSGroup : false,
                                languageId: group!==null ? group.languageId :'English',
                                ProviderUsername: user.userName,
                    }}          
                    validationSchema={ GroupSchema }
                    //validationSchema={ LoginSchema }
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                        setSubmitting(true);
                        showSpinner(true); 
                        debugger
                        let url ="api/Group/AddGroup";
                        if(groupId !== null){
                            url="api/Group/updateGroup";
                        }
                        http.post(url,values)
                        .then((response)=>{
                            // setLoggedInUser(response.data);
                            // history.push('/clients');
                            showSpinner(false); 
                            setSubmitting(false);
                            history.push('/clients')
                            console.log("response",response.data)
                        })
                        .catch((err) => {
                            showSpinner(false); 
                            setSubmitting(false);
                            console.log(err)
                        })
                          
                    }}        
                >

                    {
                        ({isSubmitting, setFieldValue}) => (
                            <Form>
                                <FormGroup>
                                    <Label>Client Group Id</Label>
                                    <Field type="text" name="groupId" className="form-control"/>
                                    <ErrorMessage name="groupId" component="div" className="text-danger" />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Group name</Label>
                                    <Field type="text" name="groupName" className="form-control"/>
                                    <ErrorMessage name="groupName" component="div" className="text-danger" />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Language</Label>
                                    <Field as="select" name="languageId" className="form-control">
                                        <option value='' >select Language</option>
                                        {languages.map((item, key) =>
                                            <option value={item.languageId} >{item.langName}</option>
                                        )}
                                    </Field>
                                    <ErrorMessage name="languageId" component="div" className="text-danger" />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Is This a GSRS Group</Label>
                                    <Field as="select" name="ShowSurveyFeedback" className="form-control">
                                        <option value='' >select..</option>
                                        {yesNo.map((item, key) =>
                                            <option value={item.value} >{item.label}</option>
                                        )}
                                    </Field>
                                    <ErrorMessage name="ShowSurveyFeedback" component="div" className="text-danger" />
                                </FormGroup>

                                <div className="mt-4 text-center">
                                    <Button isSubmitting={isSubmitting} >Submit</Button>   
                                </div>   
                            </Form>
                        )
                    }

                </Formik>
            }
        </>
    )
}

export default withRouter(GroupForm)
