import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const Context = createContext({});

export const Provider = ({ children }) => {
  
    const [displayspinner, toggleSpinner] = useState(false)

    const [loaderLength, setLoaderlength] = useState(0)

    const showSpinner = status => {
        toggleSpinner(status)
    }

    const setSpinnerLength = length => {
        setLoaderlength(length)
    }

    // Make the context object:
    const loadersContext = {
        displayspinner,
        loaderLength,
        showSpinner,
        setSpinnerLength,
    }

  return (<Context.Provider value={loadersContext}>{children}</Context.Provider>);
};

export const { Consumer } = Context;

Provider.propTypes = {
    displayspinner: PropTypes.bool,
    loaderLength: PropTypes.number
};

Provider.defaultProps = {
    displayspinner: true,
    loaderLength:0
};
