import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import { Container } from 'reactstrap';
import ReactGenericTable from '../../components/ReactGenericTable'
import { UsersContext } from '../../contexts'
import { LoadersContext } from '../../contexts'
import ClientModal from '../../modals/ClientModal'
import http from '../../utils/http';
import {Label} from 'reactstrap';
import moment from 'moment';
import ors from '../../assets/images/MyOutcome_Icon_ORS_Inactive_S.jpg';
import srsInActive from '../../assets/images/MyOutcome_Icon_SRS_Inactive_S.jpg';
import client from '../../assets/images/client.png';
import couple from '../../assets/images/couple.png';
import skip from '../../assets/images/MyOutcome_Icon_Skip_Session_S.jpg';
import srsActive from '../../assets/images/MyOutcome_Icon_SRS_Active_S.png';
import orsActive from '../../assets/images/MyOutcome_Icon_ORS_Active.jpg';
import graphBlue from '../../assets/images/MyOutcome_Icon_Graph_Active_TinyS.jpg';
import graphGreen from '../../assets/images/MyOutcome_Icon_Graph_Active_TinyS_Green.jpg';
import graphYellow from '../../assets/images/MyOutcome_Icon_Graph_Active_TinyS_Yellow.jpg';
import graphRed from '../../assets/images/MyOutcome_Icon_Graph_Active_TinyS_Red.jpg';

const ClientList = ({}) => {

    const clientObj = [
        {
            adulT_SURVEY_TYPE: 1,
            clientGroup: "Acube.Group",
            clientName: "123456_4",
            clientTypeID: null,
            consultantNote: 0,
            direction: null,
            feedbackClientID: null,
            feedbackSourcesCount: 0,
            intakeDate: null,
            intakeScore: null,
            isGSRSGroup: 0,
            isGroupUser: null,
            languageId: 1,
            name: "TestMass",
            noteEnteredDate: null,
            ors: null,
            orsDate: null,
            parentUserID: 402420,
            sessionNo: null,
            signal: null,
            srs: null,
            status: "1",
            surveyOrder: null,
            userId: 404845,
            userName: "205f84d5-129b-4338-9dae-7e2bb97f86f3_4",
        }
    ]

    let history = useHistory();

    const [clients, setClients] = useState(null);

    const [ModalStatus, setModalStatus] = useState(false);

    const [clientId, setClientId] = useState(null);

    const { user, setLoggedInUser } = useContext(UsersContext);

    const { showSpinner } = useContext(LoadersContext)

    const [selectedGroup, setSelectedGroup] = useState(null)

    const [clientGroups, setClientGroups] = useState([]);

    const fetchCLient = () => {
        let values= {}
        values.userId=user.userId;
    
        // let userId = 402419

        if(selectedGroup !== null && selectedGroup !== '') {
            values.groupId = selectedGroup;
        }
        else{
            values.groupId = 0;
        }

        showSpinner(true)
        http.post('api/user/GetProviderClientData', values)
        .then(function (response) {
            console.log("useEffect", response.data)
            //setLoggedInUser(response.data)
            setClients(response.data)
           showSpinner(false)
        })
        .catch(function (error) {
            setClients(clientObj)
            showSpinner(false)
        })        
    }
    
    useEffect(() => {
        debugger
        if (clients === null) {
            fetchCLient();
        }
    });
    
    useEffect(() => {
        debugger
        fetchCLient();
    },[selectedGroup]);

    useEffect(() => {
        console.log("clientGroups",clientGroups)
        if(clientGroups.length===0){
            http.get('api/Group/GetGroups?providerName='+user.userName)
            .then(function (response) {
                console.log("useEffect1",response.data)
                setClientGroups(response.data)
            })
            .catch(function (error) {
                
            })
        }
    },[clientGroups])

    const toggle = () => {
        setModalStatus(!ModalStatus)
    }
    
    const handleGraphStatusCllick = (clientId, surveyOrder) => {
        showSpinner(true)
        http.get('api/client/Getsurveyurlsso?ClientID='+clientId)
        .then(function (response) {
            showSpinner(false)
            window.open( response.data, "_blank" )
        })
        .catch(function (error) {
            showSpinner(false)
        })
    }
    const handleSurveyCllick = (clientId,surveyOrder) => {
        debugger
        showSpinner(true)
        if(surveyOrder==null){
            surveyOrder=1;
        }
        http.get('api/client/Getgraphurlsso?ClientID='+clientId+'&SurveyTypeID='+surveyOrder)
        .then(function (response) {
            showSpinner(false)
            window.open( response.data, "_blank" )
        })
        .catch(function (error) {
            showSpinner(false)
        })
    }

    return (
        <Container>
            <h2 className="text-center mt-4">Clients</h2>
            <div className="row">
                <div className="col-8">
                    <select 
                        name="GroupId" 
                        className="form-control"
                        onChange={e => (console.log("s",e.target.value), setSelectedGroup(e.target.value))}
                    >
                        <option value='' >Select Client Group</option>
                        {clientGroups.map((item, key) =>
                            <option value={item.userId} >{item.userName}</option>
                        )}
                    </select>
                </div>
                <div className="col-4 ml-auto text-right">
                    <button className="btn btn-sm btn-success mx-1" onClick={() => {history.push('/clients/new')}}>
                        <i className="fas fa-plus"></i> Add Client
                    </button>
                    <button className="btn btn-sm btn-success mx-1" onClick={() => {history.push('/groups/new')}}>
                        Create Group
                    </button>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="table-responsive col-12">
                    {clients !== null &&
                        <ReactGenericTable
                            tableclassName={"table"}
                            data={clients}
                            hideColumns={["userId"]}
                            columns={[
                                {
                                    Header: "Client Id",
                                    accessor: "userId"
                                },
                                {
                                    Header: "Type",
                                    accessor: "clientTypeID",
                                    Cell: ({ row }) => (
                                        <>
                                            {
                                                row.values.clientTypeID === 'CoupleClient' &&
                                                <img className="img-responsive"
                                                    src={couple}
                                                    alt="Couple"
                                                />
                                            }  
                                            {
                                                row.values.clientTypeID !== 'CoupleClient' &&
                                                <img className="img-responsive"
                                                    src={client}
                                                    alt="SRS Active"
                                                />
                                            }    
                                        </>
                                    )
                                },
                                {
                                    Header: "Client Name",
                                    accessor: "clientName",
                                },
                                {
                                    Header: "Client Group",
                                    accessor: "clientGroup",
                                },
                                {
                                    Header: "Last Session",
                                    accessor: "orsDate",
                                    Cell: ({ row }) => (
                                        <p>
                                            {row.values.orsDate !== null && moment(row.values.orsDate).format("YYYY-MM-DD")}
                                        </p>
                                    )
                                },
                                {
                                    Header: "Survey",
                                    accessor: "surveyOrder",
                                    
                                    Cell: ({ row }) => (
                                        <>
                                            {
                                                (row.values.surveyOrder === null || 
                                                row.values.surveyOrder === 0 ||
                                                row.values.surveyOrder === 1  ) &&
                                                    <img 
                                                        onClick={() => {handleSurveyCllick(row.values.userId, row.values.surveyOrder)}} 
                                                        className="img-responsive"
                                                        src={orsActive}
                                                        alt="ORS Active"
                                                    />
                                            }

                                            {
                                                row.values.surveyOrder == 2 &&
                                                <img 
                                                    onClick={() => {handleSurveyCllick(row.values.userId, row.values.surveyOrder)}}
                                                    className="img-responsive"
                                                    src={srsActive}
                                                    alt="SRS Active"
                                                />
                                            }
                                        </>
                                    )
                                },
                                // {
                                //     Header: "Skip",
                                //     accessor: "sessionNo",
                                //     Cell: ({ row }) => (
                                //         <>
                                //         {
                                //             (row.values.sessionNo !== null &&
                                //                 row.values.sessionNo > 0 &&
                                //                 !user.userAdminReadOnly && 
                                //                 !user.skipLastSession
                                //             ) &&
                                //             <a href="https://google.com" target="_blank">
                                //                 <img className="img-responsive"
                                //                     src={skip}
                                //                     alt="SRS Active"
                                //                 />
                                //             </a>
                                //         }
                                        
                                //         </>
                                //     )
                                // },
                                {
                                    Header: "Status",
                                    accessor: "signal",
                                    Cell: ({ row }) => (
                                        <>
                                            {
                                                row.values.signal === 0 &&
                                                <img className="img-responsive"
                                                    src={graphRed}
                                                    alt="View client report status"
                                                    onClick={() => {handleGraphStatusCllick(row.values.userId,row.values.surveyOrder)}}
                                                />
                                            }

                                            {
                                                row.values.signal === 1 &&
                                                <img className="img-responsive"
                                                    src={graphYellow}
                                                    alt="View client report status"
                                                    onClick={() => {handleGraphStatusCllick(row.values.userId,row.values.surveyOrder)}}
                                                />
                                            }

                                            {
                                                row.values.signal === 2 &&
                                                <img className="img-responsive"
                                                    src={graphGreen}
                                                    alt="View client report status"
                                                    onClick={() => {handleGraphStatusCllick(row.values.userId,row.values.surveyOrder)}}
                                                />
                                            }

                                            {
                                                row.values.signal !== 0 &&
                                                row.values.signal !== 1 &&
                                                row.values.signal !== 2 &&
                                                <img className="img-responsive"
                                                    src={graphBlue}
                                                    alt="View client report status"
                                                    onClick={() => {handleGraphStatusCllick(row.values.userId,row.values.surveyOrder)}}
                                                />
                                            }
                                        </>                                        
                                    )
                                },

                                {
                                    Header: "Action",
                                    accessor: "id",
                                    Cell: ({ row }) => (
                                        <>
                                            <button className="btn btn-sm btn-warning" onClick={() => { history.push('/clients/'+row.values.clientName)}}>
                                                <i className="fas fa-edit"></i> Edit
                                            </button>
                                            &nbsp;
                                            {/* 
                                                <button className="btn btn-sm btn-danger">
                                                    <i className="fas fa-trash"></i> Delete
                                                </button> 
                                            */}
                                        </>
                                    )
                                }
                            ]}
                            className="custom-table -striped -highlight custom-react-table"
                        />
                    }
                </div>
            </div>
            <div className="row">
                <ClientModal
                    isShowing={ModalStatus}
                    hide={toggle}
                    clientId={clientId}
                    setClients={setClients}
                    setClientId={setClientId}
                />
            </div>
        </Container>
    )

}

export default ClientList;