import React  from 'react';
import ClientList from '../../components/clients/ClientList'

const ClientsPage  = () => {
    return(
        <div className="Home">
            <ClientList/>
        </div>
    )
}

export default ClientsPage;