import React from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/auth/LoginPage"

import ClientsPage from "./pages/clients/ClientsPage"
import AddEditClientsPage from "./pages/clients/AddEditClientsPage"

import AddEditGroupsPage from "./pages/groups/AddEditGroupsPage"

const Routes = () => {
    return (
        <Switch>

            <PrivateRoute exact path="/clients" component={ClientsPage} />
            <PrivateRoute exact path="/clients/:id"  component={AddEditClientsPage} />
            <PrivateRoute exact path="/groups/:id"  component={AddEditGroupsPage} />
            <PrivateRoute exact path="/login" component={LoginPage} />
            <PrivateRoute exact path="/" component={HomePage} />
        </Switch>
    )

}

export default Routes;