import React, { useEffect, useContext, useState } from 'react'
import 'react-loading-bar/dist/index.css'
import '../assets/css/loader.css';
import { LoadersContext } from '../contexts'

const TopLoader = ({color}) => {

    const { displayspinner, loaderLength , setSpinnerLength } = useContext(LoadersContext);

    const [styles, setStyles] = useState(null)
   
    useEffect(() => {

        let styles = {
            backgroundColor: color,
            display: displayspinner === true ? 'block' : 'none',
            width: `${displayspinner === true ? loaderLength : 100}%`
        }

        if(displayspinner === true && loaderLength < 100) {
            setTimeout(() => setSpinnerLength(loaderLength + 10), 1000);
        }

        setStyles(styles)

    }, [displayspinner, loaderLength])

    
    return (
        <div>
            <div className="LoadingContainer">
                <div className="LoadingBar" style={styles}>
                    <div className="LoadingPeg">

                    </div>
                </div>
            </div>

            {
                displayspinner === true &&

                <div className="SpinningContainer" >
                    <div className="SpinningIcon" style={{borderColor: color}}>
                    </div>
                </div>
            }
                
            

        </div>
    )
}


export default TopLoader;