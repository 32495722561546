import React from 'react';

import Login from "../../components/Auth/Login";


const LoginPage = ({ history }) => {

  return ( <Login/> )
}

export default LoginPage;