import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Button = ({ children, onClick, isSubmitting, disabled = false, block = false }) =>
	<button 
		type="submit" 
		disabled={isSubmitting === true || disabled ? true : false} 
		onClick={onClick} 
		className={
			classNames(
				'btn btn-primary', 
				{
					'loading': isSubmitting,
					'btn-block': block
				}
			)
		}
	>
		{children}
	</button>

Button.propTypes = {
  onClick: PropTypes.func
}

export default Button