import React,{ useContext, useState, useEffect} from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import  console from 'console';
// import http from '../utils/http';
import axios from 'axios';
import qs from 'querystring';
import notify from '../utils/notify'
import moment from 'moment';
import {FormGroup, Label} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Button } from '../components'
import { UsersContext } from '../contexts'
import http from '../utils/http';
import { ClientSchema }  from '../validations'
import CreatableSelect from 'react-select/creatable';
import { LoadersContext } from '../contexts'

//import { group } from 'console';
const ClientForm = ({clientId,hide,client}) => {

    const months = [];
    const years = [];

    const surveyFeedbacks = [
        {
            label: "Yes",
            value: "yes"
        },
        {
            label: "No",
            value: "no"
        },
    ];

    const now = moment();

    for (var i = 1; i <= 12; i++) {
        months.push({
            value:i,
            label:i
        });
    }

    for (var i = 1970; i <= now.format('YYYY'); i++) {
        years.push({
            value:i,
            label:i
        });
    }

    const { user,setLoggedInUser } = useContext(UsersContext);

    const { showSpinner } = useContext(LoadersContext)

    let history = useHistory();

    const [genders, setGenders] = useState([]);

    const [clientGroups, setClientGroups] = useState([]);

    const [ethiicities, setEthinicities] = useState([]);

    const [states, setStates] = useState([]);

    const [languages, setLanguages] = useState([]);

    useEffect(() => {
  
       if(genders.length===0){
       http.get('api/client/Getgenders')
       .then(function (response) {
           console.log("Getgender",response.data)
           setGenders(response.data)
       })
       .catch(function (error) {
       
      })
    }

    },[genders]);

    useEffect(() => {
        if(clientGroups.length===0){
        http.get('api/Group/GetGroups?providerName='+user.userName)
        .then(function (response) {
            console.log("useEffect1",response.data)
            setClientGroups(response.data)
        })
        .catch(function (error) {
        
       })
    }
      

    },[clientGroups]);

    useEffect(() => {
      
      if(states.length===0){
      http.get('api/client/GetStatesProvinces')
      .then(function (response) {
          console.log("Getstate",response.data)
          setStates(response.data)
      })
      .catch(function (error) {
      
     })
    }
    

    },[states]);

    useEffect(() => {
     
     if(languages.length===0){
     http.get('api/client/GetLanguages')
     .then(function (response) {
         console.log("GetLanguages",response.data)
         setLanguages(response.data)
     })
     .catch(function (error) {
     
    })
}
   

    },[languages]);

    useEffect(() => {
       
    if(ethiicities.length===0){
    http.get('api/user/GetEthnicity')
    .then(function (response) {
        console.log("GetEthnicity",response.data)
        setEthinicities(response.data)
    })
    .catch(function (error) {
    
   })
}
    },[ethiicities]);
    console.log("client",client)
    return (
<>
{  (clientId === null || (clientId !== null && client !== null)) &&
        <Formik
            initialValues={{ 
                ClientId:client!==null ? client.id:'',
                ClientUserName: client!==null ? client.clientName:'',
                phone:client!==null ? client.phone:'',
                BirthMonth:client!==null ? client.dateofbirthm: now.format('M'),
                BirthYear:client!==null ? client.dateofbirthy:now.format('YYYY'),
                gender:client!==null ? client.gender:'',
                userTags:client!==null ?'':'',
                GroupName:client!==null ? client.GroupName:'',
                ethinicity:client!==null ? client.enthnicityId:'',
                address:client!==null ? client.address:'',
                city:client!==null ? client.city:'',
                provinceState:client!==null ? client.provinceStateID:'',
                postal:client!==null ? client.postal:'',
                email:client!==null ? client.email:'',
                numDependants:client!==null ? client.numDependants:0,
                consultNotes:client!==null ? client.consultNotes:'',
                TypeofSurvey:client!==null ? client.adulT_SURVEY_TYPE:1,
                Language:client!==null ? client.languageId:'English',
                ShowSurveyFeedback: client!==null ? 'no':'no',
            }}          
            validationSchema={ ClientSchema }
            //validationSchema={ LoginSchema }
            onSubmit={(values, { setErrors, setSubmitting }) => {
                setSubmitting(true);
                showSpinner(true);
                let url ="api/client/AddClient";
                if(clientId !== null){
                    url="api/client/updateClient";
                }
                http.post(url,values)
                .then((response)=>{
                    // setLoggedInUser(response.data);
                    // history.push('/clients');
                    showSpinner(false); 
                    setSubmitting(false); 
                    history.push('/clients')
                    console.log("response",response.data)
                })
                .catch((err) => {
                    showSpinner(false); 
                    setSubmitting(false); 
                    console.log(err)
                })
            }}        
        >

            {({isSubmitting, setFieldValue}) => (
                
                
                <Form>
                        <FormGroup>
                            <Label>Client User Id</Label>
                            <Field type="text" name="ClientId" className="form-control"/>
                            <ErrorMessage name="ClientId" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Contact name</Label>
                            <Field type="text" name="ClientUserName" className="form-control"/>
                            <ErrorMessage name="ClientUserName" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Phone</Label>
                            <Field type="text" name="phone" className="form-control"/>
                            <small id="phoneHelp" class="form-text text-muted">Hint: * Country code and mobile number with no leading zeros</small>
                            <ErrorMessage name="phone" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Birth Month</Label>
                            <Field as="select" name="BirthMonth" className="form-control">
                                <option value='' >Birth Month</option>
                                {months.map((item, key) =>
                                    <option value={item.value} >{item.label}</option>
                                )}
                            </Field>
                            <ErrorMessage name="BirthMonth" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Birth Year</Label>
                            <Field as="select" name="BirthYear" className="form-control">
                                <option value='' >Select Birth Year</option>
                                {years.map((item, key) =>
                                    <option value={item.value} >{item.label}</option>
                                )}
                            </Field>
                            <ErrorMessage name="BirthYear" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Gender</Label>
                            <Field as="select" name="gender" className="form-control">
                                <option value='' >Select Gender</option>
                                {genders.map((item, key) =>
                                    <option value={item.code} >{item.gender}</option>
                                )}
                            </Field>
                            <ErrorMessage name="gender" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Tags</Label>
                            <CreatableSelect
                                        isMulti
                               
                                        options={client !== null ?client.userTags : []}
                                        placeholder="Enter tags"
                                        value={client !== null ?client.userTags:""}
                                        
                                    />
                            {/* <Field type="text" name="userTags" className="form-control"/> */}
                            <ErrorMessage name="userTags" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Client Group</Label>
                            <Field as="select" name="GroupName" className="form-control">
                                <option value='' >Select Group</option>
                                {clientGroups.map((item, key) =>
                                    <option value={item.userName} >{item.userName}</option>
                                )}
                            </Field>
                            <ErrorMessage name="GroupName" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Ethinicity</Label>
                            <Field as="select" name="ethinicity" className="form-control">
                                <option value='' >Select Ethinicity</option>
                                {ethiicities.map((item, key) =>
                                    <option value={item.value} >{item.label}</option>
                                )}
                            </Field>
                            <ErrorMessage name="ethinicity" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Address</Label>
                            <Field component="textarea" name="address" className="form-control" placeholder="Enter Address" />
                            <ErrorMessage name="address" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>City</Label>
                            <Field type="text" name="city" className="form-control" placeholder="Enter City" />
                            <ErrorMessage name="city" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Province / State</Label>
                            <Field as="select" name="provinceState" className="form-control">
                                <option value='' >Select State</option>
                                {states.map((item, key) =>
                                    <option value={item.id} >{item.stateProvince}</option>
                                )}
                            </Field>
                            <ErrorMessage name="provinceState" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Postal Code/ Zip</Label>
                            <Field type="number" name="postal" className="form-control"/>
                            <ErrorMessage name="postal" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Field type="email" name="email" className="form-control"/>
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Number of Dependants</Label>
                            <Field type="number" name="numDependants" className="form-control"/>
                            <ErrorMessage name="numDependants" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Clinical Notes</Label>
                            <Field component="textarea" name="consultNotes" className="form-control" placeholder="Enter Address" />
                            <ErrorMessage name="consultNotes" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Survey Type</Label>
                            <ErrorMessage name="TypeofSurvey" component="div" className="text-danger" />
                        </FormGroup>
                        <div role="group" aria-labelledby="my-radio-group">
                        <FormGroup >
                            <Field type="radio" name="TypeofSurvey" value={1}/>
                            &nbsp;&nbsp;
                            <Label>Adults / Adolscents (13 +)</Label>
                        </FormGroup>
                        <FormGroup >
                            <Field type="radio" name="TypeofSurvey" value={6}/>
                            &nbsp;&nbsp;
                            <Label>Group</Label>
                        </FormGroup>
                        <FormGroup >
                            <Field type="radio" name="TypeofSurvey" value={0}/>
                            &nbsp;&nbsp;
                            <Label>Children (8 - 12)</Label>
                        </FormGroup>
                        </div>
                        <FormGroup >
                            <small id="phoneHelp" class="form-text text-muted">"Children survey type" uses simpler languages and is appropriate for children</small>
                        </FormGroup>
                        <FormGroup>
                            <Label>Language</Label>
                            <Field as="select" name="Language" className="form-control">
                                <option value='' >Select Language</option>
                                {languages.map((item, key) =>
                                    <option value={item.languageId} >{item.langName}</option>
                                )}
                            </Field>
                            <ErrorMessage name="Language" component="div" className="text-danger" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Show Surveyfeedback</Label>
                            <Field as="select" name="ShowSurveyFeedback" className="form-control">
                                <option value='' >Select..</option>
                                {surveyFeedbacks.map((item, key) =>
                                    <option value={item.value} >{item.label}</option>
                                )}
                            </Field>
                            <ErrorMessage name="ShowSurveyFeedback" component="div" className="text-danger" />
                        </FormGroup>  
                        <div className="mt-4 text-center">
                            <Button isSubmitting={isSubmitting} >Submit</Button>   
                        </div>       
                </Form>
  

                
               
            )}

    </Formik>
}</>
    )
}

export default withRouter(ClientForm)
