import React, {useState, useEffect}  from 'react';
import ClientList from '../../components/clients/ClientList'
import { GroupForm } from '../../forms' 
import http from '../../utils/http';
const AddEditGroupsPage  = ({ history, match }) => {

    const [groupId, setGroupId] = useState(match.params.id !== "new" ? match.params.id : null);

    const [group, setGroup] = useState(null)

    useEffect(() => {
        if(groupId !== null) {
            http.get('api/client/GetClientByClientID?ClientID='+groupId)
            .then(function (response) {
                console.log("useEffect",response.data)
                setGroupId(response.data)
             })
             .catch(function (error) {
                
             })
        }
    }, [groupId])

    return(
        <div className="Home">
            {
                (groupId === null || (groupId !== null && group !== null)) &&

                <div className="container">
                    <div className="row mt-4">
                        <div className="col-10">
                            {   groupId !== null ? (
                                <h3 >Edit Client Group </h3>
                            ) : (
                                <h3>Add Client Group</h3>
                            )}
                        </div>
                        <div className="col-2">
                            <button className="btn btn-sm btn-success mx-1" onClick={() => {history.push('/clients')}}>
                            Return to Home
                            </button>
                        </div> 
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-10">
                            <GroupForm group={group} groupId={groupId} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AddEditGroupsPage;