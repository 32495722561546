import React,{ useContext } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
// import http from '../utils/http';
import axios from 'axios';
import qs from 'querystring';
import notify from '../utils/notify'
import {Card, Row, CardBody, Col, FormGroup, Label} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { LoginSchema }  from '../validations'
import { Button } from '../components'
import { UsersContext } from '../contexts'
import http from '../utils/http';

const LoginForm = () => {

    const { setLoggedInUser } = useContext(UsersContext);

    let history = useHistory();
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    return (

        <Formik
            initialValues={{ username: '', password: '' }}          
            validationSchema={ LoginSchema }
            onSubmit={(values, { setErrors, setSubmitting }) => {
                setSubmitting(true);
                let data= values;
                values.grant_type="password"
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/token`, qs.stringify(data), config)
                .then((response) => {
                    localStorage.setItem("Auth_Token_Info", JSON.stringify(response.data));
                    console.log("Auth_Token_Info",localStorage.getItem("Auth_Token_Info"))
                    http.post("api/user/GetLoginUserDetails", qs.stringify(localStorage.getItem("Auth_Token_Info")))
                    .then((response)=>{
                        setLoggedInUser(response.data);
                        history.push('/clients');
                        console.log("response",response.data)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    // console.log(response)
                })
                .catch((err) => {
                    console.log(err)
                })
            setSubmitting(false);  
            }}
        >

            {({isSubmitting}) => (
                
                <Row className="justify-content-center mt-4">
                   <Col md="4" sm="12" lg="4"> 
                        <Card>
                            <CardBody>
                                <Form>
                                    <div className="auth-form">
                                        <FormGroup>
                                            <Label>Email / Username</Label>
                                            <Field type="text" name="username" className="form-control" placeholder="Enter email / Username" />
                                            <ErrorMessage name="username" component="div" className="text-danger" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Password</Label>
                                            <Field type="password" name="password" className="form-control" placeholder="Enter Password" />
                                            <ErrorMessage name="password" component="div" className="text-danger" />
                                        </FormGroup>
                                        <div className="mt-4">
                                            <Button isSubmitting={isSubmitting} block>Login</Button>   
                                        </div>       
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                
               
            )}

    </Formik>

    )
}

export default withRouter(LoginForm)
