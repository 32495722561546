import React, {useState, useEffect}  from 'react';
import ClientList from '../../components/clients/ClientList'
import { ClientForm } from '../../forms' 
import http from '../../utils/http';
const AddEditClientsPage  = ({ history, match }) => {

    const [clientId, setClientId] = useState(match.params.id !== "new" ? match.params.id : null);

    const [client, setClient] = useState(null)

    useEffect(() => {
        if(clientId !== null) {
            http.get('api/client/GetClientByClientID?ClientID='+clientId)
            // http.get('api/client/GetClientByClientID?ClientID=abc')
            .then(function (response) {
                console.log("useEffect",response.data)
                setClient(response.data)
             })
             .catch(function (error) {
                
             })
        }
    }, [clientId])


    return(
        <div className="Home">
            {
                (clientId === null || (clientId !== null && client !== null)) &&
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-10">
                            {   clientId !== null ? (
                                <h3 >Edit Client</h3>
                            ) : (
                                <h3>Add Client</h3>
                            )}
                        </div>
                        <div className="col-2">
                            <button className="btn btn-sm btn-success mx-1" onClick={() => {history.push('/clients')}}>
                               Return to Home
                            </button>
                        </div>        
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-10">
                            
                            <ClientForm client={client} clientId={clientId} />
                          
                            
                        </div>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default AddEditClientsPage;