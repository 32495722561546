import React from 'react';
import { LoginForm } from '../../forms'
import { Link } from "react-router-dom";
import {Container} from 'reactstrap';

const Login = () => {

  return (
    <Container>
      <h2 className="text-center mt-4">Login</h2>
      <LoginForm />
      <div className="text-center mt-3">
        <Link className="link" to="/password/forgot">Forgot password</Link>
      </div>
    </Container>
  )

}

export default Login;