import React, { useState, useEffect, useContext } from 'react'
import { Route, useHistory } from 'react-router-dom'
import http from '../utils/http';
import notify from '../utils/notify';
import Header from './Header'
import { UsersContext } from '../contexts'
import qs from 'querystring';
import Login from "./Auth/Login";
import { LoadersContext } from '../contexts'

const PrivateRoute = ({ component: Component, location, blankPage, ...rest }) => {

    let history = useHistory();
    
    const { user, setLoggedInUser } = useContext(UsersContext)

    const { showSpinner } = useContext(LoadersContext)

    useEffect(() => {

        showSpinner(true);
        http
        .post('api/user/GetLoginUserDetails')
        .then(function (response) {
          console.log("setLoggedInUser",response.data)
          setLoggedInUser(response.data)
          showSpinner(false);
        })
        .catch(function (error) {
          showSpinner(false);
          if (error.response && (error.response.status === 401 || error.response.status === 419)) {
            setLoggedInUser(null)
            localStorage.clear();
            history.push('/login');
          } else if (error.response && error.response.status === 422) {
            notify.error(error.response.data.message)
            history.push('/login');
          }
        })
        // setLoggedInUser({userName:"test", userAdminReadOnly: false, skipLastSession: false})
    }, []);


    return (
        <>
          {
            user !== null && 
            <>
            <Header/>
            
            <Route
                {...rest}
                render={props =>
                    <Component {...props} />
                }
            />
            </>
          }
          {
            user==null &&
            <>
            <Header/>
            <Login/> 
            </>
          }
        </>
      );
}

export default PrivateRoute;